<template>
  <v-toolbar
      class="desktop-toolbar elevation-0"
      :fixed="!isHome"
      height="64px"
      :extension-height="extensionHeight"
      style="z-index:1;">
    <v-layout align-center justify-center class="toolbar-content">
      <v-flex xs2>
        <v-layout v-if="top.showBackButton">
          <v-flex xs6 class="d-flex">
            <div class="text-center">
              <v-btn @click="goBack" flat fab class="ma-0">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs8>
        <v-layout row align-center>
          <v-flex grow>
            <h3 v-if="isHome" class="starling-h3">{{ $t('app.home.subheader.welcome', { name: user ? user.firstName : '' }) }}</h3>
            <h3 v-else class="starling-h3">{{ $te(top.title) ? $t(top.title) : top.title }}</h3>
          </v-flex>
          <v-flex shrink>
            <v-layout row align-center justify-end class="logo-container">
              <v-flex v-if="isBasic || isColabelled" shrink class="d-flex">
                <template v-if="partner.logo.linkUrl">
                  <a :href="partner.logo.linkUrl" target="_blank" class="d-flex">
                    <img :src="partner.logo.imageUrl" alt="">
                  </a>
                </template>
                <template v-else>
                  <img :src="partner.logo.imageUrl" alt="" class="d-flex">
                </template>
              </v-flex>
              <template v-if="isCobranded">
                <v-flex shrink class="d-flex">
                  <img  :src="partner.theme.images.MEMBER_DEFAULT.imageUrl" alt=""/>
                </v-flex>
                <template v-if="partner.logo && !partner.logo.default">
                  <v-flex shrink>
                    <v-divider vertical inset color="darkgrey" class="logo-divider"/>
                  </v-flex>
                  <v-flex shrink>
                    <template v-if="partner.logo.linkUrl">
                      <a :href="partner.logo.linkUrl" target="_blank" class="d-flex">
                        <img :src="partner.logo.imageUrl" alt="">
                      </a>
                    </template>
                    <template v-else>
                      <img :src="partner.logo.imageUrl" alt="" class="d-flex">
                    </template>
                  </v-flex>
                </template>
              </template>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs2>
        <v-layout align-center gap-xs-1 pl-2></v-layout>
      </v-flex>
    </v-layout>
    <template v-slot:extension v-if="showExtension">
      <top-nav-extension></top-nav-extension>
    </template>
  </v-toolbar>
</template>

<script>
import { mapState } from 'vuex';
import TopNavExtension from '@/views/components/navigation/top-nav-extension.vue';

export default {
  name: 'navigation-desktop',
  components: { TopNavExtension },
  computed: {
    ...mapState('navigation', [ 'top' ]),
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
    isHome() {
      this.$log.debug('Current route', this.$route.name);
      return this.$route.name === 'home';
    },
    isBasic() {
      return !this.partner || this.partner.type === 'BASIC';
    },
    isCobranded() {
      return this.partner && this.partner.type === 'COBRANDED';
    },
    isColabelled() {
      return this.partner && this.partner.type === 'COLABELLED';
    },
    hasPartnerLogo() {
      return this.partner?.logo?.imageUrl;
    },
    extensionTabs() {
      return this.$store.getters['navigation/tabs'];
    },
    extensionProgress() {
      return this.$store.getters['navigation/progress'];
    },
    showExtension() {
      return !!this.extensionTabs || !!this.extensionProgress;
    },
    extensionHeight() {
      if (this.extensionTabs) {
        return '64px';
      } else if (this.extensionProgress) {
        return '40px';
      }
      return undefined;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.desktop-toolbar.v-toolbar {
  background-color: white;
  border-bottom: 2px solid var(--StarlingPrimary1, #2194DD);

  .toolbar-content {
    max-width: var(--content-max-width);
  }

  &.v-toolbar--extended .v-toolbar__content {
    border-bottom: 1px solid var(--StarlingLightGrey);
  }

  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0;
  }

  .logo-container {
    max-width: 200px;

    img {
      width: 100%;
      height: 40px;
      user-select: none;
    }
  }

  .logo-divider {
    display: block;
    margin: 0 15px;
    height: 34px;
  }

  &.v-toolbar--fixed .v-toolbar__content,
  &.v-toolbar--fixed .v-toolbar__extension {
    padding-left: 300px;
  }

  &.home {
    padding-top: 24px;
    margin-bottom: -91px;
    background-color: transparent;

    .logo-container img {
      height: 72px;
    }
  }

}
</style>
