var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sm-improved-toolbar",
    {
      staticClass: "mobile--toolbar",
      attrs: {
        fixed: "",
        height: "56px",
        "scroll-off-screen": !_vm.isHome,
        "scroll-threshold": 200,
        extended: _vm.showExtension,
        "extension-height": _vm.extensionHeight
      },
      scopedSlots: _vm._u(
        [
          _vm.showExtension
            ? {
                key: "extension",
                fn: function() {
                  return [_c("top-nav-extension")]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "fill-height": "", "px-1": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "side-area", attrs: { shrink: "" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.top.showBackButton,
                      expression: "top.showBackButton"
                    }
                  ],
                  staticClass: "ma-0 pa-0",
                  attrs: { flat: "", round: "" },
                  on: { click: _vm.goBack }
                },
                [
                  _c("v-icon", { staticClass: "StarlingDarkGrey--text" }, [
                    _vm._v("mdi-chevron-left")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            [
              !_vm.partner ||
              _vm.partner.type === "BASIC" ||
              _vm.partner.type === "COBRANDED"
                ? [
                    _c(
                      "v-toolbar-title",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          width: "100%",
                          padding: "0",
                          margin: "0",
                          overflow: "unset",
                          "text-overflow": "unset"
                        }
                      },
                      [
                        _vm.top.logo
                          ? [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-img", {
                                    staticClass: "no-select",
                                    staticStyle: { display: "inline-block" },
                                    attrs: {
                                      src:
                                        _vm.partner.theme.images.MEMBER_DEFAULT
                                          .imageUrl,
                                      contain: "",
                                      height: "40px",
                                      width: "90px"
                                    }
                                  }),
                                  _vm.partner &&
                                  _vm.partner.logo &&
                                  _vm.partner.logo.imageUrl &&
                                  !_vm.partner.logo.default
                                    ? [
                                        _c("v-divider", {
                                          staticClass: "mx-1",
                                          staticStyle: {
                                            "margin-top": "0",
                                            "margin-bottom": "8px !important",
                                            "min-height": "0",
                                            display: "inline-block",
                                            height: "34px"
                                          },
                                          attrs: {
                                            vertical: "",
                                            inset: "",
                                            color: "darkgrey"
                                          }
                                        }),
                                        _vm.partner.logo.linkUrl
                                          ? [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      _vm.partner.logo.linkUrl,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm.partner &&
                                                  _vm.partner.logo &&
                                                  _vm.partner.logo.imageUrl
                                                    ? _c("v-img", {
                                                        staticClass:
                                                          "no-select",
                                                        staticStyle: {
                                                          display:
                                                            "inline-block"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.partner.logo
                                                              .imageUrl,
                                                          contain: "",
                                                          height: "40px",
                                                          width: "90px"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          : [
                                              _vm.partner &&
                                              _vm.partner.logo &&
                                              _vm.partner.logo.imageUrl
                                                ? _c("v-img", {
                                                    staticClass: "no-select",
                                                    staticStyle: {
                                                      display: "inline-block"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.partner.logo
                                                          .imageUrl,
                                                      contain: "",
                                                      height: "40px",
                                                      width: "90px"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "h3",
                                    { staticClass: "starling-h3 text-wrap" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$te(_vm.top.title)
                                            ? _vm.$t(_vm.top.title)
                                            : _vm.top.title
                                        )
                                      )
                                    ]
                                  )
                                ],
                                2
                              )
                            ]
                          : [
                              _c(
                                "h3",
                                { staticClass: "starling-h3 text-wrap" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$te(_vm.top.title)
                                        ? _vm.$t(_vm.top.title)
                                        : _vm.top.title
                                    )
                                  )
                                ]
                              )
                            ]
                      ],
                      2
                    )
                  ]
                : _vm._e(),
              _vm.partner && _vm.partner.type === "COLABELLED"
                ? [
                    _c(
                      "v-toolbar-title",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          width: "100%",
                          padding: "0",
                          margin: "0",
                          overflow: "unset",
                          "text-overflow": "unset"
                        }
                      },
                      [
                        _vm.top.logo
                          ? [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _vm.partner.logo.linkUrl
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.partner.logo.linkUrl,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _vm.partner &&
                                            _vm.partner.logo &&
                                            _vm.partner.logo.imageUrl
                                              ? _c("v-img", {
                                                  staticClass: "no-select",
                                                  staticStyle: {
                                                    display: "inline-block"
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.partner.logo.imageUrl,
                                                    contain: "",
                                                    height: "40px",
                                                    width: "160px"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    : [
                                        _vm.partner &&
                                        _vm.partner.logo &&
                                        _vm.partner.logo.imageUrl
                                          ? _c("v-img", {
                                              staticClass: "no-select",
                                              staticStyle: {
                                                display: "inline-block"
                                              },
                                              attrs: {
                                                src: _vm.partner.logo.imageUrl,
                                                contain: "",
                                                height: "40px",
                                                width: "160px"
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                ],
                                2
                              )
                            ]
                          : [
                              _c(
                                "h3",
                                { staticClass: "starling-h3 text-wrap" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$te(_vm.top.title)
                                        ? _vm.$t(_vm.top.title)
                                        : _vm.top.title
                                    )
                                  )
                                ]
                              )
                            ]
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("v-spacer"),
          _c("v-flex", { staticClass: "side-area", attrs: { shrink: "" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }