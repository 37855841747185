var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      staticClass: "desktop-toolbar elevation-0",
      staticStyle: { "z-index": "1" },
      attrs: {
        fixed: !_vm.isHome,
        height: "64px",
        "extension-height": _vm.extensionHeight
      },
      scopedSlots: _vm._u(
        [
          _vm.showExtension
            ? {
                key: "extension",
                fn: function() {
                  return [_c("top-nav-extension")]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "toolbar-content",
          attrs: { "align-center": "", "justify-center": "" }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs2: "" } },
            [
              _vm.top.showBackButton
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { staticClass: "d-flex", attrs: { xs6: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-0",
                                  attrs: { flat: "", fab: "" },
                                  on: { click: _vm.goBack }
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs8: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-center": "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _vm.isHome
                      ? _c("h3", { staticClass: "starling-h3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("app.home.subheader.welcome", {
                                name: _vm.user ? _vm.user.firstName : ""
                              })
                            )
                          )
                        ])
                      : _c("h3", { staticClass: "starling-h3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$te(_vm.top.title)
                                ? _vm.$t(_vm.top.title)
                                : _vm.top.title
                            )
                          )
                        ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "logo-container",
                          attrs: {
                            row: "",
                            "align-center": "",
                            "justify-end": ""
                          }
                        },
                        [
                          _vm.isBasic || _vm.isColabelled
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "d-flex",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _vm.partner.logo.linkUrl
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "d-flex",
                                            attrs: {
                                              href: _vm.partner.logo.linkUrl,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: _vm.partner.logo.imageUrl,
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    : [
                                        _c("img", {
                                          staticClass: "d-flex",
                                          attrs: {
                                            src: _vm.partner.logo.imageUrl,
                                            alt: ""
                                          }
                                        })
                                      ]
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.isCobranded
                            ? [
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "d-flex",
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.partner.theme.images
                                            .MEMBER_DEFAULT.imageUrl,
                                        alt: ""
                                      }
                                    })
                                  ]
                                ),
                                _vm.partner.logo && !_vm.partner.logo.default
                                  ? [
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c("v-divider", {
                                            staticClass: "logo-divider",
                                            attrs: {
                                              vertical: "",
                                              inset: "",
                                              color: "darkgrey"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _vm.partner.logo.linkUrl
                                            ? [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "d-flex",
                                                    attrs: {
                                                      href:
                                                        _vm.partner.logo
                                                          .linkUrl,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          _vm.partner.logo
                                                            .imageUrl,
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            : [
                                                _c("img", {
                                                  staticClass: "d-flex",
                                                  attrs: {
                                                    src:
                                                      _vm.partner.logo.imageUrl,
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs2: "" } },
            [
              _c("v-layout", {
                attrs: { "align-center": "", "gap-xs-1": "", "pl-2": "" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }